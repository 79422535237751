:active,
:focus {
    outline: none;
}

*,
:after,
:before {
    margin: 0;
    padding: 0;
    font: inherit;
    box-sizing: border-box;
    border: 0;
}

h3 {
    font-size: 1.5rem;
    line-height: 120%;
    margin-bottom: 24px;
    margin-top: 50px;
    font-weight: 700;
}

h4 {
    font-size: 1.2rem;
    line-height: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: 700;
}

h5 {
    font-size: 1rem;
    line-height: 100%;
    margin-bottom: 15px;
    margin-top: 30px;
    font-weight: 600;
}

h6 {
    font-size: .9rem;
    margin-bottom: 15px;
    font-weight: 400;
}

@media screen and (max-width: 991px) {
    h3 {
        font-size: 1rem;
        line-height: 170%;
        margin-bottom: 24px;
        margin-top: 40px;
        font-weight: 700;
    }
    h4 {
        font-size: 0.8rem;
        line-height: 100%;
        margin-bottom: 20px;
        margin-top: 30px;
        font-weight: 700;
    }
    h5 {
        font-size: 0.8rem;
        line-height: 100%;
        margin-bottom: 15px;
        margin-top: 30px;
        font-weight: 600;
    }
    h6 {
        font-size: 0.7rem;
        margin-bottom: 15px;
        font-weight: 400;
    }
}

b {
    font-weight: bolder;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

img {
    max-width: 100%;
}

main {
    // overflow: hidden;
}

html {
    margin-top: 0 !important;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    background: transparent;
}

input[type='checkbox'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='radio'],
input[type='text'],
input[type='tel'],
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0;
    margin: 0;
    resize: none;
}

a {
    text-decoration: none;
    color: inherit;
}