@import '../styles/variables';

.SliderInput {
    width: 100%;
    display: block;
    position: relative;
    height: 8px;

    &_trackFilled {
        position: absolute;
        height: 100%;
        top: 50%;
        z-index: 1;
        pointer-events: none;
        border-radius: 100px 0 0 100px;
        background-color: rgba($medium-red-violet, .75);
        transform: translateY(-50%);
    }

    &_tooltip {
        position: absolute;
        top: -28px;
        left: 50%;
        background: $dove-gray;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        padding: 4px 8px;
        font-size: .75rem;
        line-height: 160%;
        font-weight: 500;
        color: #FFFFFF;
        transform: translate(-50%, -100%);

        img {
            position: absolute;
            left: 50%;
            bottom: 1px;
            transform: translate(-50%, 100%);
        }
    }

    &_bottom {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &_label {
        font-size: .875rem;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: $dusty-gray;
    }

    input.SliderInput_input {
        width: 100%;
        height: 8px;
        display: block;
        background-color: transparent;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 8px;
            position: relative;
            display: block;
            background: #E6E6E6;
            border-radius: 100px;
            cursor: pointer;
        }

        &::-moz-range-track {
            width: 100%;
            height: 8px;
            position: relative;
            display: block;
            background: #E6E6E6;
            border-radius: 100px;
            cursor: pointer;
        }
      
        &::-webkit-slider-thumb {
            width: 40px;
            height: 40px;
            background: $medium-red-violet;
            box-shadow: 0px 4px 8px rgba(185, 52, 125, 0.18), 0px 0px 2px rgba(185, 52, 125, 0.12), 0px 0px 1px rgba(185, 52, 125, 0.04);
            border-radius: 50%;
            cursor: pointer;
            margin-top: -15px;
            z-index: 2;
        }

        &::-moz-range-thumb{
            width: 40px;
            height: 40px;
            background: $medium-red-violet;
            box-shadow: 0px 4px 8px rgba(185, 52, 125, 0.18), 0px 0px 2px rgba(185, 52, 125, 0.12), 0px 0px 1px rgba(185, 52, 125, 0.04);
            border-radius: 50%;
            cursor: pointer;
            margin-top: -15px;
        }
        
        &::-moz-focus-outer {
            border: 0;
        }
    }

}