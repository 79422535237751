.Theme {
  transition: background-color .2s ease-in-out;

  &_light {
    background-color: #F3F4F6;
  }

  &_dark {
    background-color: #2F3136;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
}