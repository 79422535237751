@import '../styles/variables';

.Search {
    border: 1px solid $primary;
    background-color: #fff;
    border-radius: 66px;
    padding: 8px 24px;
    position: relative;

    input.Search_input {
        padding: 8px 0;
        font-size: .875rem;
        line-height: 120%;
        color: $dusty-gray;
        width: calc(100% - 60px);
    }

    &_btn {
        position: absolute;
        top: 3px;
        right: 4px;
        background-color: $primary;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}