@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.SideNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    width: 304px;
    z-index: 10000;
    transition: transform .3s ease-in-out;
    transform: translateX(-100%);

    &__open {
        transform: translateX(0);
    }

    &_dark {
        background-color: $dark-300;

        .SideNav {
            &_top {
                background-color: $dark-300;
            }

            &_menuItem {
                color: $grey-300;
                border-color: #43454A;
            }

            &_secondMenuItem {
                color: $grey-300;
            }
        }
    }

    &_light {
        background-color: #fff;

        .SideNav {
            &_top {
                background-color: #fff;
            }

            &_menuItem {
                color: $mine-shaft;
            }

            &_secondMenuItem {
                color: $dove-gray;
            }
        }
    }

    &_top {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        padding: 20px 28px;
    }

    &_arrowBtn {
        width: 15px;
        height: 15px;
        display: block;
        cursor: pointer;

        img {
            display: block;
            width: 100%;
        }
    }

    &_closeBtn {
        display: none;
        width: 18px;
        height: 18px;
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 2px;
            background-color: $dove-gray;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &_menuItem {
        padding: 24px;
        border-bottom: 1px solid #F7F7F7;
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;

        a {
            display: flex;
            align-items: center;
        }
    }

    &_menuItem_icon {
        display: block;
        max-width: 24px;
        max-height: 24px;
        margin-right: 16px;
    }

    &_secondMenuItem {
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;
        margin-top: 27px;
        padding: 0 24px;
    }

    @media screen and (min-width: $breakpoint-md) {
        left: auto;
        right: 0;
        transform: translateX(100%);

        &__open {
            transform: translateX(0);
        }

        &_top {
            display: flex;
            justify-content: flex-end;
            padding: 18px;
        }

        &_arrowBtn {
            display: none;
        }

        &_closeBtn {
            display: block;
        }

        &_menuItem {
            font-size: 1rem;
        }
    }
}