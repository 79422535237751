@import 'libs/_reset';
@import 'abstracts/_colors';
@import 'abstracts/_breakpoints';
@import '_mdb-overrides';
@import '_swiper-overrides';

body {
    font-family: 'Roboto', sans-serif !important;
    font-display: swap;
}
