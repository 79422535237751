@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.FilterGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &_listItem {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    &_icon {
        display: block;
    }
    
    &_name {
        width: 93px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.875rem;
        line-height: 150%;
        text-align: center;
        color: $mine-shaft;
        margin-right: 34px;
    }

    &_list {
        display: flex;
        align-items: center;
    }

    &_listItem {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    &_seemore {
        width: 32px;
        height: 8px;
        display: block;
        cursor: pointer;
        position: relative;
        span {
            display: block;
            margin: auto;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $medium-red-violet;
            &::before,
            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $medium-red-violet;
                position: absolute;
                top: 0;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
}