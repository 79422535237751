@import '../../styles/abstracts/_colors';
@import '../../styles/abstracts/_breakpoints';

.Meetup {

    &_banner {
        margin-top: 70px;
        position: relative;
        margin-bottom: 30px;
        
        &_img {
            width: 100%;
            display: block;
            border-radius: 0 0 16px 16px;
            height: 225px;
            object-fit: cover;
        }

        &_btns {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 24px 25px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_btn {
            cursor: pointer;
            display: block;
            position: relative;
            z-index: 1;

            img {
                display: block;
                max-width: 18px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(83, 83, 83, 0.573494) 19.27%, rgba(255, 255, 255, 0) 43.23%);
        }
    }

    &_top {
        margin-bottom: 24px;
    }

    &_date {
        font-size: .75rem;
        line-height: 150%;
        color: $dove-gray;
        margin-bottom: 2px;
    }

    &_heading {
        margin-bottom: 4px;
    }

    &_details {
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;
        color: $dove-gray;
    }

    &_author {
        display: flex;
        align-items: center;

        &_img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: block;
            object-fit: cover;
            margin-right: 12px;
        }

        &_signature {
            font-weight: 500;
            font-size: .75rem;
            line-height: 150%;
            color: $dusty-gray;

            a {
                color: $medium-red-violet;
            }
        }

    }
    
    &_share {
        display: none;
    }

    &_section {
        margin-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #E6E6E6;

        &_heading {
            margin-bottom: 20px;
        }

        &_text {
            font-weight: 500;
            font-size: .75rem;
            line-height: 150%;
            color: $mine-shaft;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_registerBtn {
            display: block;
            margin-top: 16px;
            font-weight: 700;
            font-size: .875rem;
            line-height: 150%;
            color: $medium-red-violet;
        }

        &_benefitList {
    
            &_item {
                position: relative;
                font-weight: 500;
                font-size: .75rem;
                line-height: 160%;
                color: $mine-shaft;
                padding-left: 24px;
                margin-bottom: 16px;
    
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 12px;
                    height: 12px;
                    background-image: url('../../assets/img/icons-new-design/check--green.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
    
                &:nth-child(even) {
                    padding-right: 0;
                }
            }
        }
    }

    &_speaker {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        &_img {
            width: 152px;
            border-radius: 4px;
            margin-right: 12px;
            margin-bottom: 12px;
        }

        &_group {
            width: 100%;
        }

        &_heading {
            margin-bottom: 2px;
        }

        &_detail {
            font-weight: 500;
            font-size: .75rem;
            line-height: 150%;
            color: $mine-shaft;
        }

        &_bottom {
            display: block;
            width: 100%;
            margin-top: 20px;
        }
    }

    @media screen and (min-width: $breakpoint-md) {

        &_banner {
            margin-bottom: 80px;
            
            &_btns {
                display: none;
            }

            &_img {
                max-height: 590px;
                height: auto;
                object-position: 50% 10%;
                border-radius: 0px;
            }

            &::after {
                content: none;
            }
        }

        &_top {
            margin-bottom: 40px;
        }

        &_date {
            font-size: .875rem;
            margin-bottom: 6px;
        }

        &_heading {
            margin-bottom: 6px;
        }

        &_author {

            &_img {
                width: 52px;
                height: 52px;
                margin-right: 16px;
            }

            &_signature {
                font-size: .875rem;
            }
        }

        &_meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_share {
            display: flex;
            align-items: center;

            &_text {
                font-weight: 500;
                font-size: .875rem;
                line-height: 150%;
                color: $dove-gray;
                margin-right: 24px;
            }

            &_btn {
                max-height: 30px;
                max-width: 36px;
                margin-right: 24px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &_section {

            &_heading {
                margin-bottom: 32px;
            }

            &_text {
                font-size: .875rem;
                margin-bottom: 32px;
            }

            &_registerBtn {
                margin-top: 32px;
            }

            &_benefitList {
        
                &_item {
                    font-size: .875rem;
                    line-height: 150%;
                    padding-left: 32px;
                    margin-bottom: 24px;
        
                    &::before {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

        }

        &_speaker {

            &_img {
                width: 288px;
                border-radius: 8px;
                margin-right: 104px;
                margin-bottom: 0;
            }
            
            &_bottom {
                width: auto;
            }

            &_heading {
                margin-bottom: 8px;
            }

            &_detail {
                font-size: .875rem;
            }

            &_group {
                width: calc(100% - 394px);
            }
        }
    }
}