@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.PricingTableItem {
    width: 100%;
    padding: 18px 14px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;

    &_label {
        position: absolute;
        left: 50%;
        top: 0;
        max-width: 100%;
        border-radius: 14px;
        background-color: #2F80ED;
        padding: 2px 28px;
        font-weight: 500;
        font-size: .75rem;
        line-height: 160%;
        color: #FFFFFF;
        text-align: center;
        transform: translate(-50%, -50%);
    }

    &_top {
        padding: 10px 0;
        border-bottom: 1px solid #E6E6E6;
    }

    &_title {
        font-size: .875rem;
        line-height: 150%;
        font-weight: 700;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
    }

    &_priceText {
        text-align: center;
        padding-right: 12%;
    }

    &_oldPrice {
        font-size: .625rem;
        line-height: 230%;
        color: $dusty-gray;
        text-decoration: line-through;
        margin-right: 4px;
    }

    &_price {
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 150%;
        color: #000000;
        margin-right: 2px;
    }

    &_billed {
        font-weight: 500;
        font-size: .625rem;
        line-height: 230%;
        color: #000000;
    }

    &_promoText {
        font-weight: 500;
        font-size: .625rem;
        line-height: 150%;
        color: $green;
        text-align: center;
    }

    &_list {
        margin-top: 20px;

        &_item {
            position: relative;
            padding-left: 24px;
            font-weight: 500;
            font-size: .625rem;
            line-height: 180%;
            color: $dove-gray;
            margin-bottom: 12px;


            &::before {
                content: '';
                position: absolute;
                left: 3px;
                top: 4px;
                width: 10px;
                height: 10px;
                background-image: url('../assets/img/icons-new-design/check--green.svg');
                background-size: contain;
                background-repeat: no-repeat;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &_bottom {
        margin-top: 21px;
    }

    &_button {
        width: 100%;
    }

    &__bordered {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }

    &__large {
        padding: 24px 16px;

        .PricingTableItem {

            &_title {
                font-size: 1rem;

            }

            &_oldPrice {
                font-size: .75rem;
            }

            &_price {
                font-size: 1.5rem;
            }

            &_billed {
                font-size: .75rem;
            }

            &_promoText {
                font-size: .75rem;
            }

            &_list_item {
                font-size: .75rem;

                &::before {
                    top: 5px;
                    width: 12px;
                    height: 12px;
                }
            }

            &_bottom {
                margin-top: 24px;
            }

        }
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 30px 14px 40px;

        &_label {
            padding: 6px 22px;
            border-radius: 24px;
            font-size: .875rem;
        }

        &_top {
            padding: 0 0 16px;
        }

        &_title {
            font-size: 1rem;
            margin-bottom: 10px;
        }

        &_oldPrice {
            font-size: .75rem;
        }

        &_price {
            font-size: 1.75rem;
        }

        &_billed {
            font-size: .75rem;
        }

        &_promoText {
            font-size: .75rem;
            margin-top: 4px;
        }

        &_list {
            &_item {
                font-size: .75rem;

                &::before {
                    top: 5px;
                }
            }
        }

        &_bottom {
            margin-top: 36px;
        }

        &__large {
            padding: 40px 16px;

            .PricingTableItem {

                &_top {
                    padding: 0 0 20px;
                }

                &_title {
                    font-size: 1.25rem;
                    margin-bottom: 6px;
                }

                &_oldPrice {
                    font-size: .875rem;
                }

                &_price {
                    font-size: 2rem;
                }

                &_billed {
                    font-size: .875rem;
                }

                &_promoText {
                    font-size: .875rem;
                    margin-top: 4px;
                }

                &_list_item {
                    font-size: .875rem;

                    &::before {
                        top: 7px;
                    }
                }

                &_bottom {
                    margin-top: 40px;
                }
            }
        }
    }
}
