@import '../../styles/abstracts/_colors';
@import '../../styles/abstracts/_breakpoints';

.StudyMaterialSingle {

    &_banner {
        margin-top: 70px;
        position: relative;
        margin-bottom: 30px;
        
        &_img {
            width: 100%;
            display: block;
            border-radius: 0 0 16px 16px;
            height: 225px;
            object-fit: cover;
        }

        &_btns {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 24px 25px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_btn {
            cursor: pointer;
            display: block;
            position: relative;
            z-index: 1;

            img {
                display: block;
                max-width: 18px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(83, 83, 83, 0.573494) 19.27%, rgba(255, 255, 255, 0) 43.23%);
        }
    }

    &_top {
        margin-bottom: 32px;
    }

    &_heading {
        margin-bottom: 12px;
    }

    &_detail {
        font-size: .875rem;
        line-height: 150%;
        color: $dusty-gray;
    }

    &_text {
        font-weight: 500;
        font-size: .75rem;
        line-height: 150%;
        color: $mine-shaft;
        margin-bottom: 32px;
    }

    &_item {
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 16px;

        &_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }

        &_heading {
            font-weight: 700;
            font-size: .75rem;
            line-height: 150%;
            color: $mine-shaft;
            max-width: calc(100% - 20px);
        }

        &_icon {
            display: block;
            transition: transform .3s ease-in-out;
        }

        &_bottom {
            max-height: 0;
            overflow: hidden;
            text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
            transition-duration: 0.5s;
            transition-property: max-height;
        }

        &_description {
            margin-top: 20px;
            border-top: 1px solid #E6E6E6;
            padding-top: 12px;
            font-weight: 500;
            font-size: .75rem;
            line-height: 150%;
            color: $dove-gray;
        }

        &__active {
            .StudyMaterialSingle_item {
                &_icon {
                    transform: rotate(180deg);
                }

                &_bottom {
                    max-height: 100rem;
                    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
                    transition-duration: 0.5s;
                    transition-property: max-height;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-md) {

        &_banner {
            margin-bottom: 60px;
            
            &_btns {
                display: none;
            }

            &_img {
                max-height: 590px;
                height: auto;
                object-position: 50% 10%;
                border-radius: 0px;
            }

            &::after {
                content: none;
            }
        }

        &_text {
            font-size: .875rem;
            margin-bottom: 40px;
        }

        &_item {
            padding: 44px 32px;
            margin-bottom: 40px;

            &_heading {
                font-size: .875rem;
            }

            &_icon {
                width: 16px;
            }

            &_description {
                margin-top: 32px;
                padding-top: 32px;
                font-size: .875rem;
            }
        }
    }
}