@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.Message {
    padding: 6px 28px 10px;
    position: relative;
    width: 185px;

    &_img {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 8px;
        left: 8px;
        transform: translate(-50%, -50%);
    }

    &_text {
        font-weight: 500;
        font-size: .75rem;
        line-height: 160%;
        color: $mine-shaft;
    }

    &__short {
        background: #F2F2F2;
        border-radius: 12px;
    }

    &__long {
        width: 285px;
        padding: 8px 24px;
        background-color: #FFFFFF;
        border-radius: 12px;

        .Message {
            &_text {
                text-align: center;
            }
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 14px 60px;
        width: 272px;

        &_img {
            width: 48px;
            height: 48px;
        }

        &_text {
            font-size: .875rem;
            line-height: 150%;
        }

        &__long {
            width: 410px;
            padding: 20px 32px;
        }

    }
}