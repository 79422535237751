@import '../styles/variables';

.Statistics {
    overflow: hidden;
    padding: 20px 0;

    &_dark {
        .Statistics {
            &_heading {
                color: #fff;
            }
        }
    }

    &_light {
        .Statistics {
            &_heading {
                color: $mine-shaft;
            }
        }
    }

    &_heading {
        text-align: center;
        margin: 64px 0;
    }

    &_chart {
        border-radius: 8px;
        padding: 24px 16px;

        &_dark {
            background-color: $dark-300;
        }

        &_light {
            background-color: #FFFFFF;
        }

        &_heading {
            &_dark {
                color: #fff;
            }

            &_light {
                color: $mine-shaft;
            }
        }

        &_top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;
        }

        &_select {
            background-color: transparent;
            font-size: .875rem;
            font-weight: 500;
            color: $primary;
            background-image: url('../assets/img/icons-new-design/arrow--bottom--yellow.svg');
            background-repeat: no-repeat,repeat;
            background-position: right 4px top 50%, 0 0;
            background-size: 8px auto, 100%;
            padding-right: 20px;
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 44px 0;

        &_heading {
            margin: 150px 0 72px;
        }

        &_chart {
            padding: 40px 80px;

            &_select {
                font-size: 1rem;
                background-size: 16px auto, 100%;
                padding-right: 32px;
            }
        }
    }
}

.FilterBar {
    position: sticky;
    top: $TopNavHeight;
    padding: 16px 0;
    z-index: 998;
    transition: background-color .2s ease-in-out;

    &_light {
        background-color: #fff;
    }

    &_dark {
        background-color: $dark-300;

        @media (min-width: $breakpoint-md) {
            background-color: $dark-400;
        }
    }

    &_heading {
        margin: 24px 0;
    }

    &_mobileFilter {
        display: flex;
        flex-wrap: wrap;
        margin: 32px -6px 0;

        &_item {
            margin: 0 6px 20px;
        }
    }

    &_desktopFilter {
        display: none;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 32px 0;
        height: $FilterBarDesktopHeight;
        border-radius: 0 0 20px 20px;

        &_heading {
            margin-top: 0;
            margin-bottom: 40px;
        }

        &_mobileFilter {
            display: none;
        }

        &_desktopFilter {
            display: block;
        }
    }
}