@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.BlogSingle {
    background: #FFFFFF;

    &_banner {
        position: relative;
        margin-bottom: 30px;
        
        &_img {
            width: 100%;
            display: block;
            border-radius: 0 0 16px 16px;
            height: 225px;
            object-fit: cover;
        }

        &_btns {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 24px 25px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_btn {
            cursor: pointer;
            display: block;
            position: relative;
            z-index: 1;

            img {
                display: block;
                max-width: 18px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(83, 83, 83, 0.573494) 19.27%, rgba(255, 255, 255, 0) 43.23%);
        }
    }

    &_top {
        margin-bottom: 24px;
    }

    &_label {
        font-size: .5rem;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        padding: 6px 8px;
        background: $primary;
        border-radius: 54px;
        display: inline-block;
    }

    &_heading {
        margin: 4px 0;
    }

    &_details {
        display: flex;
        flex-wrap: wrap;
    }

    &_detail {
        display: flex;
        align-items: center;
        font-size: .75rem;
        line-height: 150%;
        color: $dusty-gray;
        margin-right: 20px;

        img {
            width: 16px;
            margin-right: 4px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &_meta {
        margin-bottom: 32px;
    }

    &_author {
        display: flex;
        align-items: center;

        &_imgWrapper {
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            border-radius: 50%;
            padding: 6px;
        }

        &_name {
            font-weight: 500;
            font-size: .75rem;
            line-height: 150%;
            color: $primary;
        }

        &_detail {
            font-size: .75rem;
            line-height: 150%;
            color: $dusty-gray;
        }
    }

    &_share {
        display: none;
    }

    &_content {
        padding-bottom: 40px;
        border-bottom: 1px solid #E6E6E6;

        &_img {
            display: block;
            margin: 0 auto 24px;
        }
    }

    &_text {
        font-size: .75rem;
        line-height: 150%;
        color: $mine-shaft;
        margin-bottom: 24px;
    }

    &_bottom {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    &_clapBtn {
        background: $primary;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &_sliderSection {
        margin-top: 40px;
        padding-bottom: 40px;

        &_heading {
            margin-bottom: 24px;
        }

        &_slider {
            :global(.swiper-button-prev), 
            :global(.swiper-button-next) {
                display: none;
            }
        }

        &_slide {
            width: 284px;
            padding: 10px;

            &_job {
                display: flex;
                align-items: flex-start;
                background-color: #FFFFFF;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 8px;
                padding: 20px 16px;

                &_imgWrapper {
                    width: 56px;
                    height: 56px;
                    background-color: #F5F5F5;
                    border-radius: 8px;
                    padding: 16px;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_img {
                    display: block;
                }

                &_content {
                    width: calc(100% - 64px);
                }

                &_heading {
                    font-size: .875rem;
                    margin-bottom: 2px;
                }

                &_detail {
                    font-weight: 500;
                    font-size: .75rem;
                    line-height: 150%;
                    color: $dove-gray;
                    margin-bottom: 2px;
                }

                &_salary {
                    font-weight: 600;
                    font-size: .75rem;
                    line-height: 150%;
                    color: $primary;
                    margin-top: 12px;
                }
            }
        }

    }

    @media screen and (min-width: $breakpoint-md) {

        &_banner {
            margin-bottom: 60px;
            
            &_btns {
                display: none;
            }

            &_img {
                max-height: 590px;
                height: auto;
                object-position: 50% 10%;
                border-radius: 0px;
            }

            &::after {
                content: none;
            }
        }

        &_top {
            margin-bottom: 40px;
        }

        &_label {
            font-size: .75rem;
            line-height: 200%;
            padding: 4px 16px;
        }

        &_heading {
            margin: 16px 0;
        }

        &_detail {
            font-size: .875rem;
            font-weight: 500;
            margin-right: 40px;
            
            img {
                width: 20px;
                margin-right: 8px;
            }
        }

        &_meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 60px;
        }

        &_author {

            &_imgWrapper {
                width: 52px;
                height: 52px;
                margin-right: 16px;
            }

            &_name {
                font-size: .875rem;
            }

            &_detail {
                font-size: .875rem;
            }
        }

        &_share {
            display: flex;
            align-items: center;

            &_text {
                font-size: 1.25rem;
                font-weight: 500;
                color: #999999;
                margin-right: 24px;
            }

            &_btn {
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 24px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &_text {
            font-size: 1rem;
            margin-bottom: 32px;
        }

        &_content {
            padding-bottom: 60px;
            
            &_img {
                margin-bottom: 32px;
            }
        }

        &_bottom {
            margin-top: 60px;
        }

        &_clapBtn {
            width: 92px;
            height: 92px;
        }

        &_sliderSection {
            margin-top: 60px;
            padding-bottom: 60px;

            &_heading {
                margin-bottom: 32px;
            }

            &_slider {
                padding: 0 64px;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 64px;
                    height: 100%;
                    background-color: #fff;
                    z-index: 2;
                }

                &::after {
                    left: auto;
                    right: 0;
                }

                :global(.swiper-button-prev), 
                :global(.swiper-button-next) {
                    margin-top: 0;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                :global(.swiper-button-prev) {
                    left: 0;
                    right: auto;
                }

                :global(.swiper-button-next) {
                    right: 0;
                    left: auto;
                }

                :global(.swiper-button-prev:after ), 
                :global(.swiper-button-next:after) {
                    content: '';
                    width: 14px;
                    height: 24px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url('../assets/img/icons-new-design/arrow--short--left--gray.svg');
                    background-size: contain;
                }

                :global(.swiper-button-next:after) {
                    transform: translate(-50%, -50%) rotate(180deg);
                }
            }

            &_slide {
                width: 460px;

                &_job {
                    border-radius: 12px;
                    padding: 20px 44px;

                    &_imgWrapper {
                        width: 80px;
                        height: 80px;
                        margin-right: 40px;
                    }

                    &_content {
                        width: calc(100% - 120px);
                    }

                    &_heading {
                        font-size: 1rem;
                        margin-bottom: 4px;
                    }

                    &_detail {
                        font-size: .875rem;
                        margin-bottom: 4px;
                    }

                    &_salary {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}