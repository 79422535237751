@import '../styles/abstracts/_colors';
@import '../styles/abstracts/_breakpoints';

.BrandItem {
    background: #FFFFFF;
    border-radius: 8px;
    transition: box-shadow .2s ease-in-out;
    
    &_wrapper {
        padding: 24px 20px;
    }

    &_img {
        width: 100%;
        height: 160px;
        object-fit: cover;
        border-radius: 4px;
        display: block;
        margin-bottom: 12px;
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_logo {
        display: block;
        width: 42px;
    }

    &_heading {
        font-weight: bold;
        font-size: .875rem;
        line-height: 170%;
        color: $mine-shaft;
    }

    &_category {
        font-weight: 500;
        font-size: .75rem;
        line-height: 150%;
        color: $dusty-gray;
    }

    &:hover {
        box-shadow: -1px 13px 20px rgba(0, 0, 0, 0.05), 6px 7px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.05);
    }
}