// Fonts, Icons & Separators
$mine-shaft: #333333;
$dove-gray: #666666;
$dusty-gray: #999999;
$dark-gray: #4F4F4F;
$mercury: #E5E5E5;
$light-red-violet: #D52E8C;

// Primary color
$medium-red-violet: #B9347D;
$pale-rose: #FFE0F1;

// Secondary color
$pink-salmon: #FF9A9E;
$apricot-peach: #FAD0C4;

$secondary-gradient: linear-gradient(180deg, $pink-salmon 19.03%, $apricot-peach 116.68%, $apricot-peach 117.66%);

// Other colors
$orange: #FF7000;
$orange-secondary: #FEEDE0;

$green: #00D48B;
$green-secondary: #D7F4EA;

$blue: #007DFE;
$blue-secondary: #E0EFFE;

$red: #FF2E4A;
$red-secondary: #FDEDEF;

$purple: #B219FE;
$purple-secondary: #F4E0FE;

$primary: #F4AC21;

$dark-300: #37393F;
$dark-400: #2F3136;

$grey-300: #BDBDBD;
$grey-400: #50545C;