@import '../../styles/abstracts/_colors';
@import '../../styles/abstracts/_breakpoints';

.StudyMaterialItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: box-shadow .2s ease-in-out;

    &_heading {
        font-weight: 700;
        font-size: .875rem;
        line-height: 150%;
        text-align: center;
        color: $mine-shaft;
    }

    &:hover {
        box-shadow: -1px 13px 20px rgba(0, 0, 0, 0.05), 6px 7px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.05);
    }
}