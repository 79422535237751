@import "../styles/variables";

.MobileFilterButton {
    width: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &_imgWrapper {
        width: 100%;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
    }

    &_text {
        font-size: 0.625rem;
        line-height: 240%;
        text-align: center;
        letter-spacing: -0.01em;
        transition: color $transition-base;
    }

    &__dark {
        .MobileFilterButton {
            &_text {
                color: #fff;
            }
        }
    }

    &__light {
        .MobileFilterButton {
            &_text {
                color: #231F20;
            }
        }
    }

    &__orange {
        .MobileFilterButton {
            &_imgWrapper {
                background: $orange-secondary;
                box-shadow: 0px 4px 8px rgba(254, 222, 197, 0.18), 0px 0px 2px rgba(254, 222, 197, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
            }
        }
    }
    &__green {
        .MobileFilterButton {
            &_imgWrapper {
                background: $green-secondary;
                box-shadow: 0px 10px 20px rgba(181, 244, 223, 0.18), 0px 2px 6px rgba(181, 244, 223, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
            }
        }
    }
    &__blue {
        .MobileFilterButton {
            &_imgWrapper {
                background: $blue-secondary;
                box-shadow: 0px 10px 20px rgba(191, 222, 254, 0.18), 0px 2px 6px rgba(191, 222, 254, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
            }
        }
    }
    &__red {
        .MobileFilterButton {
            &_imgWrapper {
                background: #FEE0E4;
                box-shadow: 0px 10px 20px rgba(254, 197, 206, 0.18), 0px 2px 6px rgba(254, 197, 206, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
            }
        }
    }
}