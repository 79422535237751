@import '../styles/variables';

.Popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    transition: transform .2s ease;
    transform: scale(0);

    &__dark {
        background-color: $dark-300;
    }

    &__light {
        background-color: #fff;
    }

    &__open {
        transform: scale(1);
        transition: transform .3s cubic-bezier(0.42, 0, 0.49, 1.26);
    }

    &__apply {
        top: auto;
        bottom: 0;
        transform: translateY(100vh);
        border-radius: 40px 40px 0 0;

        &.Popup__open {
            transform: translateY(0);
            transition: transform .3s ease-out;
        }

        .Popup {
            &_content {
                height: auto;
                padding: 72px 24px 40px;
            }
        }
    }

    &_closeBtn {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 18px;
        height: 18px;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 2px;
            background-color: $dove-gray;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &_img {
        width: 100%;
        display: block;
    }

    &_content {
        padding: 80px 24px 150px;
        height: 100vh;
        overflow-y: auto;
    }

    &_btn {
        display: block;
        margin: 24px auto 0;
    }

    @media screen and (min-width: $breakpoint-md) {
        top: 0;
        left: 0;
        border-radius: 12px;
        max-width: 808px;
        height: 80vh;
        margin-left: calc((100vw / 2) - 404px);
        margin-top: 10vh;
        overflow-y: auto;

        &_content {
            padding: 80px 24px 60px;
            max-width: 644px;
            height: auto;
            overflow: unset;
            margin: 0 auto;
        }

        &_closeBtn {
            top: 34px;
            right: 34px;
            width: 28px;
            height: 28px;
            
            &::before,
            &::after {
                height: 4px;
            }
        }

        &__apply {
            height: auto;
            max-height: 80vh;
            max-width: 590px;
            bottom: auto;
            transform: scale(0);
            margin-left: calc((100vw / 2) - 295px);
            margin-top: calc((100vh / 2) - 245px);
    
            &.Popup__open {
                transform: scale(1);
                transition: transform .3s cubic-bezier(0.42, 0, 0.49, 1.26);
            }
    
            .Popup {
                &_content {
                    padding: 40px;
                }
            }
        }
    }
}